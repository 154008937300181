import { Switch } from '@chakra-ui/react';
import {
  ImageNodeData,
  StatBlockNodeData,
  StudioNodeType,
} from '@common/studio-types';
import { Field, RadioButtons, Textarea } from '@maestro/components';
import React from 'react';
import { Hint } from '../Hint';
import { uniquenessOptions } from './shared';

type Props = {
  onStudioNodeDataChange: (
    studioNode: ImageNodeData | StatBlockNodeData,
  ) => void;
  studioNodeData: ImageNodeData | StatBlockNodeData;
};

const isImageNode = (
  studioNodeData: ImageNodeData | StatBlockNodeData,
): studioNodeData is ImageNodeData => {
  return studioNodeData.type === StudioNodeType.Image;
};

const isStatBlockNode = (
  studioNodeData: ImageNodeData | StatBlockNodeData,
): studioNodeData is StatBlockNodeData => {
  return studioNodeData.type === StudioNodeType.StatBlock;
};

export const GenerateImageWithAiFields: React.FC<Props> = (props) => {
  const nodeData = props.studioNodeData;
  const imageUrl = isImageNode(nodeData)
    ? nodeData.imageUrl
    : nodeData.image?.url;

  const shouldGenerateImageSwitch = (
    <Field label="Generate Image">
      <Switch
        size="lg"
        isChecked={!!nodeData.generateImage}
        onChange={(e) =>
          props.onStudioNodeDataChange({
            ...nodeData,
            generateImage: e.target.checked ? {} : undefined,
          })
        }
      />
    </Field>
  );

  if (isStatBlockNode(nodeData) && !nodeData.generateImage) {
    return shouldGenerateImageSwitch;
  }

  return (
    <>
      {isStatBlockNode(nodeData) && (
        <>
          {shouldGenerateImageSwitch}
          <Field label="Image Prompt">
            <Textarea
              placeholder="Tell AI how to generate the image, the context from stat block will be added to this prompt"
              value={nodeData.generateImage?.prompt ?? ''}
              onChange={(e) =>
                props.onStudioNodeDataChange({
                  ...nodeData,
                  generateImage: {
                    ...(nodeData.generateImage ?? {}),
                    prompt: e.target.value,
                  },
                })
              }
            />
          </Field>
        </>
      )}
      <Field label="Negative prompt">
        <Textarea
          placeholder="Tell AI what to avoid when generating this node"
          value={nodeData.generateImage?.negativePrompt ?? ''}
          onChange={(e) =>
            props.onStudioNodeDataChange({
              ...nodeData,
              generateImage: {
                ...(nodeData.generateImage ?? {}),
                negativePrompt: e.target.value,
              },
            })
          }
        />
      </Field>
      {!!imageUrl && (
        <Field label="Result Uniqueness">
          <Hint>
            Indicate how unique you want the result to be compared to the node
            image.
          </Hint>
          <RadioButtons
            options={uniquenessOptions}
            value={nodeData.generateImage?.uniqueness}
            onChange={(selection) =>
              props.onStudioNodeDataChange({
                ...nodeData,
                generateImage: {
                  ...(nodeData.generateImage ?? {}),
                  uniqueness: selection,
                },
              })
            }
          />
        </Field>
      )}
    </>
  );
};
